@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);
.list {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-left: 0;
}

.select-all {
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  color: #212529 impor !important;
  font-size: 11px !important;
}

.errorPage {
  height: 100%;
  min-height: inherit;
}

.errorPage .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  max-width: 700px;
  min-height: inherit;
  width: 75%;
}

@media (max-width: 650px) {
  .errorPage .container {
    width: 85%;
  }
}

.errorPage .compcontainer {
  height: 13rem;
  padding: 1rem 0;
  width: 75%;
}

@media (max-width: 650px) {
  .errorPage .compcontainer {
    height: 10rem;
  }
}

.errorPage .compcontainer svg {
  -webkit-animation: bouncy 1300ms linear infinite;
          animation: bouncy 1300ms linear infinite;
  max-height: 100%;
  max-width: 100%;
}

.errorPage .instructions {
  background: #fefefe;
  border-radius: 0.25rem;
  border: 1px solid #dcdcdc;
  height: auto;
  padding: 1rem;
  width: 80%;
}

@media (max-width: 650px) {
  .errorPage .instructions {
    width: 100%;
  }
}

.errorPage .instructions h2 {
  color: #e30528;
  font-size: 1.25em;
  line-height: 1.3;
}

@media (max-width: 650px) {
  .errorPage .instructions h2 {
    font-size: 1.05em;
  }
}

.errorPage .instructions span {
  color: #122125;
  font-size: 1.15em;
  line-height: 1.5;
}

@media (max-width: 650px) {
  .errorPage .instructions span {
    font-size: 1em;
  }
}

.errorPage .instructions .step {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  margin: 0.5rem 0;
  width: 100%;
}

.errorPage .instructions .step .icon {
  align-self: center;
  height: 1.25rem;
  width: 1.25rem;
}

@media (max-width: 650px) {
  .errorPage .instructions .step .icon {
    height: 1rem;
    width: 1rem;
  }
}

.errorPage .instructions .step span {
  display: inline-block;
  line-height: 1.5;
  padding-left: 0.5rem;
  width: 80%;
}

@-webkit-keyframes bouncy {
  0% {
    -webkit-transform: translateY(10px) translateX(0) rotate(0);
            transform: translateY(10px) translateX(0) rotate(0);
  }

  25% {
    -webkit-transform: translateX(-10px) rotate(-10deg);
            transform: translateX(-10px) rotate(-10deg);
  }

  50% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
  }

  75% {
    -webkit-transform: translateX(10px) rotate(10deg);
            transform: translateX(10px) rotate(10deg);
  }

  100% {
    -webkit-transform: translateY(10px) translateX(0) rotate(0);
            transform: translateY(10px) translateX(0) rotate(0);
  }
}

@keyframes bouncy {
  0% {
    -webkit-transform: translateY(10px) translateX(0) rotate(0);
            transform: translateY(10px) translateX(0) rotate(0);
  }

  25% {
    -webkit-transform: translateX(-10px) rotate(-10deg);
            transform: translateX(-10px) rotate(-10deg);
  }

  50% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
  }

  75% {
    -webkit-transform: translateX(10px) rotate(10deg);
            transform: translateX(10px) rotate(10deg);
  }

  100% {
    -webkit-transform: translateY(10px) translateX(0) rotate(0);
            transform: translateY(10px) translateX(0) rotate(0);
  }
}

body > iframe[style*='2147483647'] {
  display: none;
}

