.list {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-left: 0;
}

.select-all {
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  color: #212529 impor !important;
  font-size: 11px !important;
}
